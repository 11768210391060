import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import * as types from '../actionTypes';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {parse} from 'airborne/types';
import air from 'airborne/store/modules/search_hotels/actions/air';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {serializeTSPM} from 'airborne/homepage2/helpers/search';
import {getFareGroupKey, getFlightOptionKeys} from 'airborne/air/store/fare_search/selectors';
import {getAirExchangeBookingKey, isAirExchangeFlow} from 'airborne/store/modules/exchange_air/selectors';
import {patchCheckoutForm} from './checkoutForm';
import {
    getCheckoutData, getCheckoutForm,
    getIdCards,
    getPassports,
    getSeparatedTicketsCheckoutData,
    getVisas,
} from 'airborne/store/modules/checkout_air/selectors';
import {addPassportFieldsToCheckoutSkip} from './skip';
import {TABS as TRAVEL_DOC_TABS} from 'airborne/air/checkout/TravelDocumentation/PassportSchema';
import {TRAVELER_NUMBER_COUNTRIES} from 'airborne/air/checkout/helpers/checkoutForm';

import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import {isAirSeparatedTicketsMode} from "airborne/air/store/pricing/selectors";

const parseTravelerNumbers = (checkoutData) => {
    const knownTravelerNumbers = checkoutData['secure_flight']['known_traveler_numbers']?.map(number => ({...number, type: 'known_traveler_number'})) || [];
    const redressNumbers = checkoutData['secure_flight']['redress_numbers']?.map(number => ({...number, type: 'redress_number'})) || [];
    const allowedTravelerNumbers = [...knownTravelerNumbers, ...redressNumbers].filter(number => Object.values(TRAVELER_NUMBER_COUNTRIES).includes(number['country_valid_for']));
    return Object.values(groupBy(allowedTravelerNumbers, 'country_valid_for')).map((countryNumbers) => {
        return countryNumbers.reduce((acc, number) => ({
            ...acc,
            'country_valid_for': number['country_valid_for'],
            [number.type]: number.number,
        }), {});
    });
};

const parseFFNumbers = (checkoutData) => {
    const tfFFNumbers = get(checkoutData, 'tf_ff_numbers', []);
    return tfFFNumbers.map(({'airline_code': vendor, 'ff_number': number}) => ({vendor, number}))
};

export const prefillCheckoutData = () => (dispatch, getState) => {
    const checkoutData = getCheckoutData(getState());
    const defaultMeal = checkoutData['meal_request'];
    const isTSASupported = checkoutData['tsa_supported'];
    const travelerNumbers = parseTravelerNumbers(checkoutData);
    const tfFFNumbers = parseFFNumbers(checkoutData);
    const passports = getPassports(getState());
    const isSeparatedTickets = isAirSeparatedTicketsMode(getState());
    const separatedTicketsCheckoutData = getSeparatedTicketsCheckoutData(getState());
    const separatedTicketsFields = getCheckoutForm(getState()).value.separatedTicketsFields || [];

    dispatch(patchCheckoutForm({
        toChange: {value: {
            travelDocumentationTab: TRAVEL_DOC_TABS.NOT_APPLICABLE,
            'visa_documents': getVisas(getState()),
            passports,
            idCards: getIdCards(getState()),
            ssrCodes: [{code: ''}],
            passportIndex: passports.length > 0 ? 0 : null,
            ...(travelerNumbers.length && isTSASupported ? {'traveler_numbers': travelerNumbers} : null),
            ...(isSeparatedTickets
                ? {
                    separatedTicketsFields: separatedTicketsCheckoutData.map((checkoutData, ticketIndex) => ({
                        ...(separatedTicketsFields[ticketIndex] || {}), // let the data prefilled on previous steps remain
                        meal: checkoutData['meal_request'],
                        tfFFNumbers: parseFFNumbers(checkoutData)
                    }))
                }
                : {
                    meal: defaultMeal,
                    tfFFNumbers,
                }
            ),
        }}
    }));

    const isExchangeFLow = isAirExchangeFlow(getState());
    if (isExchangeFLow) {
        dispatch(addPassportFieldsToCheckoutSkip());
    }
};

export const requestCheckoutData = ({ticketIndex} = {}) => (dispatch, getState) => {
    const fareGroupKey = getFareGroupKey(getState(), ticketIndex);
    const flightOptionKeys = getFlightOptionKeys(getState(), ticketIndex);
    const profile = getPnrProfile(getState(), 0);

    return air('POST', '/air/get_checkout_data/', dropEmpty({
        'configuration_id': getSelectedConfiguration(getState()) || profile.company,
        'fare_group_key': fareGroupKey,
        'flight_option_keys': flightOptionKeys,
        'pnr_id': profile.pnr,
        'exchange_key': getAirExchangeBookingKey(getState()),
        'extended_credit_card_list': true,
        ...serializeTSPM(profile),
    })).then(response => parse('airCheckoutData', response))
}

export function loadCheckoutData({rethrow = false} = {}) {
    return function loadCheckoutDataD(dispatch) {

        dispatch({type: types.AIR_CHECKOUT_DATA_LOADING});

        return dispatch(
            requestCheckoutData()
        ).then((data)=> {
            dispatch({
                type: types.AIR_CHECKOUT_DATA_LOADED,
                checkoutData: data,
            });
        }).catch((response)=> {
            dispatch({type: types.AIR_CHECKOUT_DATA_FAIL});
            if (rethrow) {
                throw response;
            }
        });
    };
}

export function loadCheckoutDataSeparatedTickets ({rethrow = false}) {
    return async function loadCheckoutDataD(dispatch) {

        dispatch({type: types.AIR_CHECKOUT_DATA_LOADING});

        try {
            const [firstCheckoutData, secondCheckoutData] = await Promise.all([
                dispatch(requestCheckoutData({ticketIndex: 0})),
                dispatch(requestCheckoutData({ticketIndex: 1}))
            ]);

            dispatch({
                type: types.AIR_CHECKOUT_DATA_LOADED,
                checkoutData: firstCheckoutData,
                ticketIndex: 0,
            });
            dispatch({
                type: types.AIR_CHECKOUT_DATA_LOADED,
                checkoutData: secondCheckoutData,
                ticketIndex: 1,
            });
        } catch(response) {
            dispatch({type: types.AIR_CHECKOUT_DATA_FAIL});

            if (rethrow) {
                throw response;
            }
        }
    }
}
