import aio from 'midoffice/helpers/aio';
import {makeAvailabilitySearch} from 'airborne/air/store/availability/actions';
import {
    getAirExchangeFormValue,
    getExchangeAvailabilityRequestParams,
    getExchangeSearchRequestParams,
} from '../selectors';
import {goToExchangeAvailability, goToExchangeFareSearch} from './params';
import {makeFareSearch} from 'airborne/air/store/fare_search/actions/searchAir';

export const requestExchangeAvailability = () => async (dispatch, getState) => {
    const destinationForm = getAirExchangeFormValue(getState());
    const requestParams = getExchangeAvailabilityRequestParams(getState());

    const promise = dispatch(makeAvailabilitySearch(requestParams, destinationForm));
    await Promise.race([promise, aio.sleep(2)]);

    dispatch(goToExchangeAvailability());
};

export const requestExchangeSearch = () => async (dispatch, getState) => {
    const destinationForm = getAirExchangeFormValue(getState());
    const requestParams = getExchangeSearchRequestParams(getState());

    const promise = dispatch(makeFareSearch(requestParams, destinationForm));
    await Promise.race([promise, aio.sleep(2)]);

    dispatch(goToExchangeFareSearch());
};
