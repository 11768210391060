import * as types from '../actionTypes';
import air from 'airborne/store/modules/search_hotels/actions/air';
import {currentCompany} from 'airborne/homepage2/helpers/search';
import {
    getCheckoutFareGroupKey,
    getFareRulesByKey,
    getIsFareRulesLoading,
} from 'airborne/air/store/fare_search/selectors';
import {apiError} from 'airborne/common/apiError';
import {showModal} from 'airborne/store/modules/header/actions/modal';
import normalize from 'airborne/search2/helpers/normalize';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';

export function showError(message=null, kind='error') {
    return showModal(kind, {message});
};


export function getFareRules(fareGroupKey, isCheckoutFlow = false, ODIndex = 0) {
    const URL = '/air/fare_rules/';
    return async function getRateDetailsD(dispatch, getState) {
        const state = getState();
        const loading = getIsFareRulesLoading(state);
        const checkoutFareGroupKey = getCheckoutFareGroupKey(state, ODIndex);
        const fareRules = getFareRulesByKey(state, fareGroupKey);
        if (loading || (fareRules && !isCheckoutFlow)) {
            return true;
        }
        const params = {
            'fare_group_key': isCheckoutFlow ? checkoutFareGroupKey : fareGroupKey,
            'configuration_id': getSelectedConfiguration(getState()) || currentCompany(getState()),
        };
        dispatch({type: types.AIR_FARE_RULES_LOADING});

        try {
            const raw = await air('POST', URL, params);
            const data = normalize(raw.fare_components);
            dispatch({type: types.AIR_FARE_RULES_LOADED, data, fareGroupKey});
            return raw;
        }
        catch (response) {
            const details = apiError(response);
            dispatch(showError(details));
            dispatch({type: types.AIR_FARE_RULES_FAIL});
            throw response;
        }

    };
}

export default getFareRules;
