import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {paymentTestIds} from './testIds';

export default class CCSelector extends React.Component {
    static propTypes = {
        accepted: PropTypes.array,
        active: PropTypes.string,
    };

    static defaultProps = {
        accepted: [],
        active: '',
    };

    render() {
        const {accepted, active} = this.props;

        return (
            <div className="form-group__label-offset">
                <div className="form-group">
                    <div className="col-xs-7">
                        {accepted.map((code)=> {
                            const codeLower = code.toLowerCase();
                            const iconClassname = classnames(
                                `cc-icon-${codeLower}`,
                                {active: codeLower === active}
                            );

                            return (<span
                                key={code}
                                data-testid={`${paymentTestIds.creditCardIcon}-${codeLower}`}
                                className={iconClassname} />);
                        })}
                    </div>
                </div>
            </div>
        );
    }
}