import moment from 'moment';
import gettext from 'airborne/gettext';

import {createSchema, required} from 'midoffice/newforms/helpers';
import {
    DateRange,
    CharField,
    NumberField,
    Field,
} from 'midoffice/newforms/fields-stateless';
import {getDateLimits} from 'airborne/homepage2/helpers/dateLimits';

const CheckinRange = {
    ...DateRange,

    validate(value={}, {strict=false}={}) {
        const {min: checkin, max: checkout} = value;

        const checkinDate = moment(checkin, 'YYYY-MM-DD');
        const checkoutDate = moment(checkout, 'YYYY-MM-DD');
        const {min: today, max: tooFar} = getDateLimits();

        if (checkin && checkinDate.diff(today, 'days') < 0) {
            return gettext('Checkin date can\'t be in the past');
        }
        if (checkin && checkinDate.diff(tooFar, 'days') >= 0) {
            return gettext('Checkin date can\'t be that far in the future');
        }

        if (!strict && checkin !== null && checkout !== null) {
            return null;
        }

        return {
            min: checkinDate.isValid() ? null : gettext('Please enter your check-in date'),
            max: checkoutDate.isValid() ? null : gettext('Please enter your check-out date'),
        };
    },
};


const AgentSearchSchema = createSchema({
    fields: {
        'destination': required(Field, ()=> gettext('Where do you want to go?')),
        'dates': createSchema(CheckinRange),
        'guests': NumberField,
        'hotel_name': CharField,
        'chains': Field,
        'special_rates': Field,
        'distance': NumberField,
    }
});

export default AgentSearchSchema;
