import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {connect} from 'react-redux';

import gettext from 'airborne/gettext';
import Loader from 'airborne/search2/Loader';
import BookButton from 'airborne/search2/MultiBookButton';
import {getDetails} from 'airborne/search2/helpers/rates';
import EmailOfferButton from 'airborne/search2/hotel/EmailOfferButton';
import {showModal} from 'airborne/store/modules/header/actions/modal';
import getRateDetails from 'airborne/store/modules/search_hotels/actions/rateDetails';
import NewDetails from 'midoffice/components/NewDetails';

function getLabels(isOnRequest) {
    return isOnRequest
        ? {
              single: gettext('Request Room'),
              multi: gettext('Request Room For'),
              remaining: gettext('Request this rate for all travelers »'),
          }
        : {
              single: gettext('Book Room'),
              multi: gettext('Book Room For'),
              remaining: gettext('Book this rate for all travelers »'),
          };
}

function mapStateProps(state, {hotelId, rateKey}) {
    const {loading, details} = getDetails(state, hotelId, rateKey);

    return {
        loading,
        details,
    };
}

const DEFAULT_BOOK_TYPES = {
    'data-non-sc': false,
    'data-sc-cheapes': false,
    'data-sc-best-rate': false,
    'data-sc-best-non-loyalty': false,
    'data-sc-best-loyalty': false,
    'data-book-button': true,
};
const BOOK_ATTR_VALUES = {
    'Non-SC': 'data-non-sc',
    'SC-cheapest': 'data-sc-cheapest',
    'SC-best-rate': 'data-sc-best-rate',
    'SC-best-non-loyalty': 'data-sc-best-non-loyalty',
    'SC-best-loyalty': 'data-sc-best-loyalty',
};

@connect(mapStateProps, {
    getRateDetails,
    onWrongButton: (hotelId, rateKey) => showModal('wrongBedType', {hotelId, rateKey}),
})
export default class RateDetails extends React.Component {
    static propTypes = {
        hotelId: PropTypes.number.isRequired,
        rateKey: PropTypes.string.isRequired,
        loading: PropTypes.bool,
        noButton: PropTypes.bool,
        details: PropTypes.object,
        onHide: PropTypes.func.isRequired,
        onWrongButton: PropTypes.func.isRequired,
        getRateDetails: PropTypes.func.isRequired,
        booktype: PropTypes.string,
    };

    componentDidMount() {
        const {rateKey, hotelId} = this.props;
        this.props.getRateDetails(hotelId, rateKey);
    }

    componentWillUnmount() {
        this.props.onHide();
    }

    getBookAttrs = booktype => {
        if (!booktype) return {};
        const booktypeArray = booktype.split(' ');
        return booktypeArray.reduce((acc, attr) => {
            return {
                ...acc,
                [BOOK_ATTR_VALUES[attr]]: true,
            };
        }, {});
    };

    handleWrongButtonClick = () => {
        const {onWrongButton, rateKey, hotelId} = this.props;
        onWrongButton(hotelId, rateKey);
    };

    renderModal(details) {
        return <NewDetails {...details} onWrongButton={this.handleWrongButtonClick} />;
    }
    render() {
        const {loading, details, rateKey, hotelId, noButton, onHide, booktype} = this.props;
        const LABELS = getLabels(details['is_on_request']);
        return (
            <Modal show onHide={onHide} className="modal--rate">
                <Modal.Header closeButton>
                    <Modal.Title>{gettext('Rate Details')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{loading || !details ? <Loader /> : this.renderModal(details)}</Modal.Body>
                {!loading && !noButton && (
                    <Modal.Footer className="clearfix">
                        <div className="pull-left">
                            <EmailOfferButton hotelId={hotelId} rateKey={rateKey} />
                        </div>
                        <BookButton
                            hotelId={hotelId}
                            rateKey={rateKey}
                            variant={details['is_on_request'] ? 'warning' : 'primary'}
                            multiBookLabel={LABELS.multi}
                            remainingBookLabel={LABELS.remaining}
                            data-booktype={booktype}
                            {...DEFAULT_BOOK_TYPES}
                            {...this.getBookAttrs(booktype)}
                        >
                            {LABELS.single}
                        </BookButton>
                    </Modal.Footer>
                )}
            </Modal>
        );
    }
}
