const EXCLUDE_FEEDBACK_ROUTES = ['/bookings/'];

function shouldExcludeFeedbackForRoute(pathname) {
    return EXCLUDE_FEEDBACK_ROUTES.some(excludedPathname => pathname.startsWith(excludedPathname));
}

export function shouldInitializeFeedbackWidget() {
    return !isFeedbackWidgetInitialized() && !shouldExcludeFeedbackForRoute(location.pathname);
}

export function shouldShowFeedbackWidget(pathname) {
    return isFeedbackWidgetInitialized() && !shouldExcludeFeedbackForRoute(location.pathname);
}

export function shouldHideFeedbackWidget(pathname) {
    return isFeedbackWidgetInitialized() && shouldExcludeFeedbackForRoute(pathname);
}

export function applyFeedbackButtonDisplay(display) {
    const feedbackButton = document.getElementById("smxFeedbackBtn");
    if (!feedbackButton) return;
    feedbackButton.style.display = display;
}


export function hideFeedbackWidget() {
    applyFeedbackButtonDisplay('none');
}

export function showFeedbackWidget() {
    applyFeedbackButtonDisplay('block');
}

function isFeedbackWidgetInitialized() {
    return window.npxUrl;
}

function loadScript(src){
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = false;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Script loading error: ${src}`));
        document.head.appendChild(script);
    });
}

export function loadFeedbackWidget() {
    const satmetrixUrl = "https://appeuw1.satmetrix.com/npxcdn/enterprise/bcdtravel/app/cx/resources/datacollection/bcdtravel_6443/intercept/bcdtravel_6443_popup_1731511035961-en_us/interceptsurvey_bcdtravel_6443_popup_1731511035961-en_us.js?a=" + new Date().getTime();
    window.npxUrl = satmetrixUrl;

    loadScript("https://code.jquery.com/jquery-3.6.0.min.js")
        .then(() => loadScript("https://code.jquery.com/jquery-migrate-3.3.2.min.js"))
        .then(() => loadScript(satmetrixUrl))
        .catch(error => {
            console.log(error);
        });
}
