import * as types from '../actionTypes';
import {FIELDS} from 'airborne/air/pricing/types';
import {
    getSelectedFlightOptions,
    getSegmentsFromSelectedFlightOptions,
} from 'airborne/air/store/availability/selectors';
import {requestFaresPricing, requestFaresPricingMultiClasses} from 'airborne/air/store/pricing/actions/request';

export const changePricingForm = ({value, errors}) => ({
    type: types.AIR_PRICING_CHANGE_FORM,
    value,
    errors,
});

const getPreselectedServicesBy = (segments, iteratee) => segments.reduce((acc, segment) => ({
    ...acc,
    [segment.segmentIdRef]: iteratee(segment),
}), {});

const prefillAndCallWithDefaultsForAmadeus = () => (dispatch, getState) => {
    const segments = getSegmentsFromSelectedFlightOptions(getState());

    const preselectedServices = getPreselectedServicesBy(segments, (segment) => segment.classes.find(
        // We need only class of service that have more than 1 seat
        (classItem) => Number(classItem.seatsStatus?.code) > 1
    )?.classOfService);

    if (Object.values(preselectedServices).every(Boolean)) {
        dispatch(changePricingForm({
            value: {
                [FIELDS.services]: preselectedServices,
                [FIELDS.ignoreClassOfService]: true,
            }
        }));
        dispatch(requestFaresPricing());
    }
};

const prefillAndCallWithDefaultsForSabre = () => async (dispatch, getState) => {
    const segments = getSegmentsFromSelectedFlightOptions(getState());

    const preselectedServicesClassA = getPreselectedServicesBy(
        segments,
        (segment) => segment.classes[0].classOfService
    );

    const preselectedServicesClassB = getPreselectedServicesBy(
        segments,
        (segment) => segment.classes[segment.classes.length - 1].classOfService
    );

    dispatch(requestFaresPricingMultiClasses([
        preselectedServicesClassA, preselectedServicesClassB
    ]));
}

export const prefillAndCallWithDefaults = () => (dispatch, getState) => {
    const fares = getSelectedFlightOptions(getState());

    if (fares.every(fare => fare?.providers?.includes('amadeus'))) {
        dispatch(prefillAndCallWithDefaultsForAmadeus());
    }

    if (fares.every(fare => fare?.providers?.includes('sabre'))) {
        dispatch(prefillAndCallWithDefaultsForSabre());
    }

};
