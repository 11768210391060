export const hotelsTestIds = {
    results: {
        hotelCard: 'hotel-card',
        hotelCardName: 'hotel-card-name',
        hotelCardProvidersTooltip: 'hotel-card-providers-tooltip',
        hotelCardRatePriceTooltipTrigger: 'hotel-card-rate-price-tooltip-trigger',
        hotelCardRatePriceTooltipContent: 'hotel-card-rate-price-tooltip-content',
        hotelCardRateDetailsButton: 'hotel-card-rateDetails-button',
        hotelCardBookingRequirementsButton: 'hotel-card-booking-requirements-button',
        hotelCardBookingRequirementsContent: 'hotel-card-booking-requirements-content',
        hotelTabs: 'hotel-tabs',
        hotelCloseButton: 'hotel-close-button',
        hotelTabsHeaderRates: 'hotel-tabs-header-rates',
        hotelTabsHeaderFacilities: 'hotel-tabs-header-facilities',
        hotelTabsHeaderPolicies: 'hotel-tabs-header-policies',
        hotelTabsHeaderAbout: 'hotel-tabs-header-about',
        hotelTabsHeaderPhotos: 'hotel-tabs-header-photos',
        hotelTabsHeaderMap: 'hotel-tabs-header-map',
        hotelsTabsContentRates: 'hotel-tabs-content-rates',
        hotelsTabsContentFacilities: 'hotel-tabs-content-facilities',
        hotelsTabsContentPolicies: 'hotel-tabs-content-policies',
        hotelsTabsContentAbout: 'hotel-tabs-content-about',
        hotelsTabsContentPhotos: 'hotel-tabs-content-photos',
        hotelsTabsContentMap: 'hotel-tabs-content-map',
        hotelCashOnlyLabel: 'hotel-cash-only-label',
        hotelRateRoomNumber: 'hotel-rate-room-number',
    },
};
