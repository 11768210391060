import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import {gettext, ngettext} from 'airborne/gettext';
import {connect} from 'react-redux';
import Glyphicons from 'midoffice/components/Glyphicons';
import Button from 'midoffice/components/Button';

import Hint from 'midoffice/components/Hint';

import {noPnrEnabled, guestTravelerEnabled} from 'airborne/search2/helpers/hyatt';
import {
    getTspmName,
    getPnrProfile,
    getPnrOid,
    getHomepagePnrIndex,
    getProfileName,
    isModification
} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getOriginalCompany, getOptions} from 'airborne/store/modules/homepage/selectors/homepage';
import {isSameCompany} from 'airborne/homepage2/helpers/pnr';
import {
    showProfileModal,
    showCompanyModal,
    discardTSPM,
} from 'airborne/store/modules/homepage/actions/tspm';
import {editPnr, deletePnr} from 'airborne/store/modules/homepage/actions/pnr';
import {getProduct} from 'airborne/store/modules/homepage/selectors/product';
import {showEmailOffers} from 'airborne/store/modules/header/actions/changeStep';
import {
    AnotherPNRCompany,
    GuestWarning,
    NoPNRWarning,
    ChangeCompany,
    TspmMismatchWarning,
    ExpiredTspmProfilesWarning,
    InfoBox,
    TspmEmailWarning,
    UnticketedAirSegment, NoProductWarning
} from './warnings';
import {getModal} from 'airborne/store/modules/header/selectors/modal';
import {Link} from 'react-router-dom';
import browserHistory from 'airborne/browserHistory';
import {getAirDisabledReason, getCarsDisabledReason, getHotelsDisabledReason} from 'airborne/homepage2/Right';
import {getCarModificationFlowBool} from 'midoffice/car-bookings/selectors';

import {isAirExchangeFlow} from 'airborne/store/modules/exchange_air/selectors';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';

function hasTspm(props) {
    const {'tspm_traveler_id': tspmId} = props;
    return Boolean(tspmId);
}

export class PNRName extends React.Component {
    static propTypes = {
        'pnr_name_first': PropTypes.string,
        'pnr_name_last': PropTypes.string,
        'pnr_name_title': PropTypes.string,
    };

    render() {
        const {
            'pnr_name_first': firstName,
            'pnr_name_last': lastName,
        } = this.props;

        return (<tr>
            <th>
                { gettext('PNR Traveler Name') }
                &nbsp;
                <Hint id="pnr name">{gettext('Traveler (guest) name as it’s recorded in the selected PNR. Note that this name cannot be changed / overwritten, and this is the name that will be transmitted to the supplier or hotel at the time of booking.')}</Hint>
            </th>
            <td>
                <div className="ag-table__cell-col">{ firstName || '—' } { lastName }</div>
            </td>
        </tr>);
    }
}

export class Company extends React.Component {
    static propTypes = {
        'company_name': PropTypes.string,
    };

    render() {
        const {'company_name': name} = this.props;
        return (<tr>
            <th>{ gettext('Company') }</th>
            <td>
                <div className="ag-table__cell-col">{ name }</div>
            </td>
        </tr>);
    }
}

class CompanyProfile extends React.Component {
    static propTypes = {
        'company_name': PropTypes.string,
        'tspm_company_name': PropTypes.string,
        'tspm_traveler_id': PropTypes.string,
        onCompanyModal: PropTypes.func.isRequired,
    };

    render() {
        const {
            'tspm_company_name': nameTSPM,
            onCompanyModal
        } = this.props;
        return (<tr>
            <th>
                { gettext('Company Name') }
                &nbsp;
                <Hint id="company name">{gettext('The TSPM Company Profile is selected based on the chosen TSPM Traveler Profile as well as traveler and company information in the PNR. This does not affect AgentSource Booking settings.')}</Hint>
            </th>
            <td>
                { hasTspm(this.props) ? (<Pencil className="enzyme-open-company" onClick={onCompanyModal} />) : null}
                <div className="ag-table__cell-col">{ nameTSPM || '—' }</div>
            </td>
        </tr>);
    }
}

const VirtualCards = ({isPaymentManagerEnabled = false, 'is_company_original': isCompanyOriginal = false, isModification = false, isExchangeFlow = false}) => {
    const isMidasEnabled = getFeatureFlag(null, 'MIDAS_ENABLED');
    if (!isMidasEnabled) {
        return null;
    }
    if (!isPaymentManagerEnabled || !isCompanyOriginal || isModification || isExchangeFlow) {
        return null;
    }
    return (
        <tr>
            <th>{ gettext('Virtual cards') }</th>
            <td>
                <Link to="/ui/payment_manager">{gettext('Manage Virtual Payments')} <span className="glyphicons glyphicons-new-window" /></Link>
            </td>
        </tr>
    );
};

VirtualCards.propTypes = {
    'isPaymentManagerEnabled': PropTypes.bool,
    'is_company_original': PropTypes.bool,
    'isModification': PropTypes.bool,
    'isExchangeFlow': PropTypes.bool,
};

export class Office  extends React.Component {
    static propTypes = {
        'oid': PropTypes.string,
    };

    render() {
        const {oid} = this.props;
        return (<tr>
            <th>{ gettext('OID/PCC') }</th>
            <td>
                <div className="ag-table__cell-col">{ oid || '—' }</div>
            </td>
        </tr>);
    }
}

@connect(null, {showEmailOffers})
class ActiveEmailOffers  extends React.Component {
    static propTypes = {
        offersCount: PropTypes.number,
        pnr: PropTypes.string,
        showEmailOffers: PropTypes.func.isRequired,
    };
    static MAX_COUNT = 9;
    static limitCounter(counter) {
        return counter > ActiveEmailOffers.MAX_COUNT
            ? `${ActiveEmailOffers.MAX_COUNT}+`
            : counter;
    }

    handleClickEmailOffers = () => {
        const {pnr} = this.props;

        browserHistory.push('/ui/email_offers/');

        this.props.showEmailOffers(pnr);
    }

    render() {
        const {offersCount} = this.props;
        if (!offersCount) { return null; }
        return (<tr>
            <th>{ gettext('Active Email Offers') }</th>
            <td> <div className="ag-table__cell-col">
                <span className="text-tag text-tag--green">{ActiveEmailOffers.limitCounter(offersCount)}</span>
                <Button bsSize="sm" className={'btn-link btn-link--narrow pull-right'} onClick={this.handleClickEmailOffers}>
                    {gettext('Email Offers Management ')} <Glyphicons bsClass={'glyphicons'} glyph={'new-window'} />
                </Button>
            </div>
            </td>
        </tr>);
    }
}


export class PNR extends React.Component {
    static propTypes = {
        'pnr': PropTypes.string,
    };

    render() {
        const {pnr} = this.props;
        return (<tr>
            <th>
                { gettext('PNR') }
            </th>
            <td>
                <div className="ag-table__cell-col">{ pnr || '—' }</div>
            </td>
        </tr>);
    }
}

class Pencil extends React.Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    handleClick = (event)=> {
        event.preventDefault();
        this.props.onClick && this.props.onClick();
    }

    render() {
        const className = classnames('ag-table__cell-edit', this.props.className);
        return (<a href="#" className={className} onClick={this.handleClick} >
            <Glyphicons bsClass="glyphicon" glyph="pencil" />
            {gettext('edit')}
        </a>);
    }
}

class ProfileName3 extends React.Component {
    static propTypes = {
        'name': PropTypes.string,
        'onProfileModal': PropTypes.func.isRequired,
    };

    renderLabel = () => {
        const {name} = this.props;
        return (
            <div className="ag-table__cell-col">{ name || '—' }</div>
        );

    }

    render() {
        const {onProfileModal} = this.props;
        return (<tr data-cy="tspm-profile-name-row">
            <th>
                { gettext('Traveler Name') }
                &nbsp;
                <Hint id="profile name">{gettext('Traveler name as it’s recorded in the traveler’s TSPM profile. Note that if this name is different from the traveler name stored in the PNR, then name stored in the PNR will be used to complete this booking and will be transmitted to the supplier or hotel.')}</Hint>
            </th>
            <td>
                <Pencil className="enzyme-open-profile" onClick={onProfileModal} />
                <Shortcut label={this.renderLabel()} shortcut={SHORTCUTS_LIST.T} action={onProfileModal} />
            </td>
        </tr>);
    }
}

class TspmEmail extends React.Component {
    static propTypes = {
        'tspm_email': PropTypes.string,
    };

    render() {
        const {'tspm_email': email} = this.props;
        return (<tr data-cy="tspm-profile-email-row">
            <th>{ gettext('Traveler Email') }</th>
            <td>
                <div className="ag-table__cell-col">{ email || '—' }</div>
            </td>
        </tr>);
    }
}

class Records extends React.Component {
    static propTypes = {
        'pnr': PropTypes.string,
        'hotel_bookings_count': PropTypes.number,
        'car_bookings_count': PropTypes.number,
        'air_bookings_count': PropTypes.number,
        'isExchangeFlow': PropTypes.bool,
        'isModification': PropTypes.bool,
    }
    static MAX_COUNT = 9;
    static limitCounter(counter) {
        return counter > Records.MAX_COUNT
            ? `${Records.MAX_COUNT}+`
            : counter;
    }
    renderCounter(label, counter, link) {
        const cls = classnames('text-tag', {
            'text-tag--green': counter > 0,
            'text-tag--gray': !counter,
        });
        const {isModification, isExchangeFlow} = this.props;
        const showButton = !isModification && !isExchangeFlow && Boolean(counter);

        return (<div className="ag-table__cell-col">
            <span className={cls}>
                {Records.limitCounter(counter)}
            </span>
            {label}
            {showButton && <Link to={link} > {gettext('Show')} <Glyphicons bsClass="glyphicons" glyph="new-window" /> </Link>}
        </div>);
    }

    render() {
        const {
            'pnr': pnr,
            'hotel_bookings_count': hotels,
            'car_bookings_count': cars,
            'air_bookings_count': flights
        } = this.props;
        if (!hotels && !cars && !flights) return null;

        return (<tr>
            <th>{ gettext('Booking Records') }</th>
            <td>
                {this.renderCounter(
                    ngettext('Hotel', 'Hotels', hotels || 0),
                    hotels || 0,
                    `/bookings/hotels/?q=${pnr}`
                )}
                {this.renderCounter(
                    ngettext('Car', 'Cars', cars || 0),
                    cars || 0,
                    `/bookings/cars/?q=${pnr}`
                )}
                {this.renderCounter(
                    ngettext('Flight', 'Flights', flights || 0),
                    flights || 0,
                    `/bookings/air/?q=${pnr}`
                )}
            </td>
        </tr>);
    }
}


class Preview extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        pnr: PropTypes.string.isRequired,
        'tspm_email': PropTypes.string,
        'is_company_original': PropTypes.bool,
        'expired_profiles_found': PropTypes.bool,
        'traveler_profile_mismatch':  PropTypes.bool,
        'company_profile_mismatch':  PropTypes.bool,
        onEdit: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        'tspm_traveler_id': PropTypes.string,
        'tspm_error': PropTypes.string,
    };

    renderTspmError(tspmError) {
        return <span className="highlight-red">{tspmError}</span>;
    }

    renderStatus() {
        const {
            pnr,
            'expired_profiles_found': expiredProfilesFound,
            'traveler_profile_mismatch': travelerMismatch,
            'company_profile_mismatch': companyMismatch,
            'tspm_traveler_id': tspmId,
            'tspm_error': tspmError,
        } = this.props;

        if (!tspmId && !!pnr && tspmError) {
            return this.renderTspmError(tspmError);
        }

        if (travelerMismatch || companyMismatch || expiredProfilesFound) {
            return (<div className="ag-table__cell-col">
                <TspmMismatchWarning {...this.props} />
                <br/>
                <TspmEmailWarning {...this.props} />
                <br/>
                <ExpiredTspmProfilesWarning {...this.props} />
            </div>);
        }

        return hasTspm(this.props)
            ? (<div className="ag-table__cell-col">
                <span className="highlight-green">{gettext('Ready')}</span>
            </div>)
            : (<div className="ag-table__cell-col">
                <span className="text-gray">
                    <em>{gettext('Guest Traveler')}</em>
                </span>
            </div>);
    }

    render() {
        const {pnr, onEdit, onDelete} = this.props;

        return (
            <div className="ag-block-list__item">
                <div className="ag-block-list__control">
                    <Button bsStyle="link" onClick={onEdit} name="edit">
                        <Glyphicons bsClass="glyphicon" glyph="pencil" />
                        {gettext('Edit')}
                    </Button>

                    <Button bsStyle="link" className="ag-block-list__danger-btn" onClick={onDelete} name="delete">
                        <Glyphicons bsClass="glyphicon" glyph="remove" />
                        {gettext('Remove')}
                    </Button>
                </div>

                <table className="table">
                    <tbody>
                        <tr>
                            <th>{gettext('PNR:')}</th>
                            <td><div className="ag-table__cell-col">{pnr}</div></td>
                        </tr>
                        <tr>
                            <th>{gettext('PNR Traveler Name:')}</th>
                            <td><div className="ag-table__cell-col">{getProfileName(this.props)}</div></td>
                        </tr>
                        <tr>
                            <th>{gettext('Status:')}</th>
                            <td>{this.renderStatus()}</td>
                        </tr>
                    </tbody>
                </table>
                <ChangeCompany {...this.props} />
                <AnotherPNRCompany {...this.props} />
                <GuestWarning {...this.props} />
                <NoPNRWarning {...this.props} />
            </div>
        );
    }
}

function mapStateToProps(state, {index}) {
    const profile = getPnrProfile(state, index);
    const originalCompany = getOriginalCompany(state);

    const carsDisabledReason = getCarsDisabledReason(state);
    const airDisabledReason = getAirDisabledReason(state);
    const hotelsDisabledReason = getHotelsDisabledReason(state);
    const isAllTabsDisabled = hotelsDisabledReason && carsDisabledReason && airDisabledReason;
    const destOptions = getOptions(state);
    const isPaymentManagerEnabled = getFeatureFlag(state, 'DISPLAY_VIRTUAL_PAYMENTS')
        || destOptions?.['payment_manager_enabled'];

    return {
        ...profile,
        oid: getPnrOid(state, index),
        noPnrEnabled: noPnrEnabled(state),
        guestEnabled: guestTravelerEnabled(state),
        sameCompany: isSameCompany(profile, originalCompany),
        modal: getModal(state),
        isAllTabsDisabled,
        isPaymentManagerEnabled,
        isModification: isModification(state) || getCarModificationFlowBool(state),
        product: getProduct(state),
    };
}

@connect((state, {index, preview})=> ({
    index: preview ? index : getHomepagePnrIndex(state),
    isExchangeFlow: isAirExchangeFlow(state),
}))
@connect(mapStateToProps, (dispatch, {index}) => ({
    onDiscardTSPM: () => dispatch(discardTSPM(index)),
    onProfileModal: () => dispatch(showProfileModal()),
    onCompanyModal: () => dispatch(showCompanyModal()),
    onEdit: () => dispatch(editPnr(index)),
    onDelete: () => dispatch(deletePnr(index)),
}))
export default class PNRSearchResult extends React.Component {
    static propTypes = {
        'index': PropTypes.number.isRequired,
        'warning': PropTypes.string,
        'pnr_name_first': PropTypes.string,
        'pnr_name_last': PropTypes.string,
        'company_name': PropTypes.string,
        'sameCompany': PropTypes.bool,
        'pnr': PropTypes.string,
        'preview': PropTypes.bool,
        'is_company_original': PropTypes.bool,
        'oid': PropTypes.string,
        'tspm_traveler_id': PropTypes.string,
        'email_offers_count': PropTypes.number,
        'guestEnabled': PropTypes.bool.isRequired,
        'isAllTabsDisabled': PropTypes.bool,
        'onDiscardTSPM': PropTypes.func.isRequired,
        'onProfileModal': PropTypes.func.isRequired,
        'tspm_email': PropTypes.string,
        'tspm_error': PropTypes.string,
        'modal': PropTypes.object,
        'isExchangeFlow': PropTypes.bool.isRequired,
        'isPaymentManagerEnabled': PropTypes.bool,
        'product': PropTypes.oneOf(['hotels', 'cars', 'air', 'rail']),
    };

    static defaultProps = {
        preview: false,
    };

    handleNoTSPM = (event)=> {
        event.preventDefault();
        if (hasTspm(this.props)) {
            this.props.onDiscardTSPM();
        }
    }

    renderNoTSPM() {
        const cls = classnames('enzyme-no-tspm text-sm', {
            disabled: !hasTspm(this.props),
        });
        return (<div className="ag-row__header">
            <h5>{ gettext('Selected TSPM Profile') }</h5>
            <a onClick={this.handleNoTSPM} href="#" className={cls}>
                {gettext('Proceed without profile »')}</a>
        </div>);
    }

    renderTspmError(tspmError) {
        return <InfoBox glyph="exclamation-sign" className="alert-danger">{tspmError}</InfoBox>;
    }

    renderErrors() {
        const {
            pnr,
            modal: {kind},
            'tspm_traveler_id': tspmId,
            'tspm_error': tspmError,
        } = this.props;

        if (!tspmId && !!pnr && tspmError) {
            return this.renderTspmError(tspmError);
        }

        if (!!kind && kind !== 'pnrSearch') {
            return null;
        }

        return (
            <>
                <TspmMismatchWarning {...this.props} />
                <TspmEmailWarning {...this.props} />
                <ExpiredTspmProfilesWarning {...this.props} />
            </>
        );
    }

    render() {
        if (this.props.preview) {
            return <Preview {...this.props} />;
        }
        const {
            guestEnabled,
            pnr,
            onProfileModal,
            'email_offers_count': offersCount,
            isAllTabsDisabled,
        } = this.props;


        return (<div className="ag-form">
            <div className="ag-row">
                <UnticketedAirSegment {...this.props} />
                <ChangeCompany {...this.props} />
                <NoProductWarning isAllTabsDisabled={isAllTabsDisabled} />
                <AnotherPNRCompany {...this.props} />
                <GuestWarning {...this.props} />
                <NoPNRWarning {...this.props} />

                <div className="item">
                    <table className="table ag-table">
                        <tbody>
                            <PNR {...this.props} />
                            <Records {...this.props} />
                            <PNRName {...this.props} />
                            <Company {...this.props} />
                            <Office {...this.props} />
                            <VirtualCards {...this.props} />
                            <ActiveEmailOffers pnr={pnr} offersCount={offersCount} />
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="ag-row">
                {guestEnabled && this.renderNoTSPM()}
                {this.renderErrors()}
                <table className="table ag-table">
                    <tbody>
                        <ProfileName3 name={getTspmName(this.props)} onProfileModal={onProfileModal} />
                        <TspmEmail {...this.props} />
                        <CompanyProfile {...this.props} />
                    </tbody>
                </table>
            </div>
        </div>);
    }
}
