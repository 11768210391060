import mergeWith from 'lodash/mergeWith';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import flatMap from 'lodash/flatMap';
import getAirExchangeRoot from './root';
import {getAirExchangeBookingTravelerPrice, isAirManualExchange} from './params';
import {getSelectedFlightPriceTravelerPrice} from 'airborne/air/store/fare_search/selectors';
import {roundToDecimalNumber} from 'airborne/utils/numbers';

export const getManualExchangeForm = (state) => getAirExchangeRoot(state).manualExchangeForm;
export const getManualExchangeFormValue = (state) => getManualExchangeForm(state).value;

export const getManualExchangeMergedTaxes = (state, formValue) => {
    // Helpers to Calculate ticketPrice:
    // How much you need to pay extra or get a refund -
    // Positive ticketPrice - Additional Cost
    // Negative ticketPrice - Refund
    const calculateCheaperCase = (currentAmount, newAmount, refundable) => {
        if (refundable) {
            return newAmount - currentAmount;
        }

        return 0;
    };
    const calculateMoreExpensiveCase = (currentAmount, newAmount) => {
        return newAmount - currentAmount;
    };

    const getTicketPriceFromTaxes = (currentAmount, newAmount, refundable) => {
        const isNewTaxCheaper = currentAmount > newAmount;

        if (isNewTaxCheaper) {
            return calculateCheaperCase(currentAmount, newAmount, refundable);
        }

        return calculateMoreExpensiveCase(currentAmount, newAmount);
    };
    const getTicketPrice = (currentAmount, newAmount, refundable) => {
        const isBothTaxes = Boolean(currentAmount && newAmount);

        if (isBothTaxes) {
            return getTicketPriceFromTaxes(currentAmount, newAmount, refundable);
        }

        return newAmount || 0;
    };

    // Group taxes by designator
    const {taxes: formTaxes} = (formValue || getManualExchangeFormValue(state));
    const {taxes: currentTaxes} = getAirExchangeBookingTravelerPrice(state);
    const {taxes: newTaxes} = getSelectedFlightPriceTravelerPrice(state);

    const getDesignator = (tax) => tax.designator;
    const accumulateTaxes = (taxes) => {
        return taxes.reduce((acc, currentTax) => {
            const indexOfTheSameTax = acc.findIndex(tax => tax.designator === currentTax.designator);
            if (indexOfTheSameTax !== -1) {
                const sameTax = acc[indexOfTheSameTax];
                const accumulatedTax = {
                    ...currentTax,
                    amount: roundToDecimalNumber(sameTax.amount + currentTax.amount),
                    originalAmount: roundToDecimalNumber(sameTax.originalAmount + currentTax.originalAmount)
                };
                acc.splice(indexOfTheSameTax, 1, accumulatedTax);
            }
            else {
                acc.push(currentTax);
            }

            return acc;
        }, []);
    };
    const buildTax = (currentTax, newTax, formTaxes) => {
        const designator = currentTax?.designator || newTax.designator;


        // Prioritize refundable from the form selector, but default to the one in response
        // If no information about refundability in response - we default one to NOT refundable
        const formTaxRefundable = formTaxes?.find((tax) => designator === tax.designator)?.refundable;
        const isRefundable = Boolean(formTaxRefundable ?? currentTax?.refundable);

        // Handle initial case and "builded" case in one place
        const currentTaxAmount = currentTax?.oldPrice || currentTax?.originalAmount;

        const newTaxAmount = newTax?.amount;
        const ticketPrice = roundToDecimalNumber(getTicketPrice(currentTaxAmount, newTaxAmount, isRefundable));

        return {
            designator,
            oldPrice: currentTaxAmount,
            newPrice: newTaxAmount,
            refundable: isRefundable,
            ticketPrice,
        };
    };

    // We need the initial taxes object to have correct interface from the beginning
    // because mergeWith aren't going to affect unique fields from the source object
    const accumulatedCurrentTaxes = accumulateTaxes(currentTaxes);
    const initialCurrentTaxes = accumulatedCurrentTaxes.map(currentTax => buildTax(currentTax));
    const accumulatedNewTaxes = accumulateTaxes(newTaxes);

    const keyedCurrentTaxes = keyBy(initialCurrentTaxes, getDesignator);
    const keyedNewTaxes = keyBy(accumulatedNewTaxes, getDesignator);

    // We merge taxes from the current ticket and new fare by Taxes Designator
    // And calculate how much you need to pay extra / or get a refund
    const mergedKeyedTaxes = mergeWith(keyedCurrentTaxes, keyedNewTaxes, (currentTax, newTax) => {
        return buildTax(currentTax, newTax, formTaxes);
    });

    return values(mergedKeyedTaxes);
};

export const getManualExchangeTaxesAdditionalCollectionTotal = (state, formValue) => {
    const taxes = getManualExchangeMergedTaxes(state, formValue);

    const taxesSum = taxes.reduce((acc, {ticketPrice}) => acc + (ticketPrice > 0 ? ticketPrice : 0), 0);

    return roundToDecimalNumber(taxesSum);
};

export const getManualExchangeTaxesRefundTotal = (state, formValue) => {
    const taxes = getManualExchangeMergedTaxes(state, formValue);

    const taxesSum = taxes.reduce((acc, {ticketPrice}) => acc + (ticketPrice < 0 ? ticketPrice : 0), 0);

    return roundToDecimalNumber(taxesSum);
};

const getAdditionalCollectionTaxesTotal = (taxes) => {
    return taxes.reduce((acc, currentTax) => {
        const {oldPrice, newPrice, refundable} = currentTax;
        if (!oldPrice) {
            return acc + newPrice;
        }

        if (newPrice && newPrice > oldPrice) {
            return acc + (newPrice - oldPrice);
        }

        return acc;

    }, 0);
};

const getRefundTaxesTotal = (taxes) => {
    return taxes.reduce((acc, currentTax) => {
        const {ticketPrice} = currentTax;

        if (ticketPrice < 0) {
            return acc + Math.abs(ticketPrice);
        }

        return acc;
    }, 0);
};

const getTotalCredit = (taxes, baseFareDifference) => {
    const refundTaxesTotal = getRefundTaxesTotal(taxes);

    return roundToDecimalNumber(
        baseFareDifference < 0
            ? refundTaxesTotal + Math.abs(baseFareDifference)
            : refundTaxesTotal
    );
};


const getPricingTaxes = (taxes) => {
    return flatMap(taxes, tax => {
        const {oldPrice, ticketPrice, designator, newPrice} = tax;

        // Case when everything was paid and no add collect or refund
        if (oldPrice && ticketPrice === 0) {
            return {
                designator,
                amount: oldPrice,
                paid: 'PD'
            };
        }

        // Case when it's an old tax, but new tax is more expensive,
        // and you need to pay extra
        if (oldPrice && ticketPrice > 0) {
            return [
                {
                    designator,
                    amount: oldPrice,
                    paid: 'PD'
                },
                {
                    designator,
                    amount: ticketPrice
                }
            ];
        }

        // Case of refund
        if (ticketPrice < 0) {
            return [
                {
                    designator,
                    amount: Math.abs(ticketPrice),
                    paid: 'RF'
                },
                // case of refund with old tax price more expensive than new tax price
                // new price tax should be passed as paid
                ...oldPrice > newPrice
                    ? [{designator, amount: newPrice, paid: 'PD'}]
                    : []
            ];
        }

        // Case when it's a new tax
        return {
            designator,
            amount: ticketPrice,
        };
    });
};

export const getManualExchangeBookParams = (state) => {
    if (!isAirManualExchange(state)) {
        return null;
    }

    const {
        baseFareDifference,
        addCollectTaxes,
        residualTaxes,
        taxes,
        penalty,
        commission
    } = getManualExchangeFormValue(state);

    const additionalCollectionTaxesTotal = getAdditionalCollectionTaxesTotal(taxes);
    const isAdditionalCollectOperation = baseFareDifference > 0 || additionalCollectionTaxesTotal > 0;
    const isRefundOperation = !isAdditionalCollectOperation;

    const taxesTotal = roundToDecimalNumber(
        isRefundOperation ?
            addCollectTaxes + residualTaxes :
            addCollectTaxes
    );
    const total = roundToDecimalNumber(taxesTotal + baseFareDifference);

    const pickSign = (cost, isRefund) => {
        if (cost === 0) {
            return 0;
        }
        return isRefund ? -cost : cost;
    };

    const operationFieldName = isRefundOperation ? 'refund' : 'additional_collection';

    return {
        [operationFieldName]: {
            'base_fare_difference': pickSign(baseFareDifference, isRefundOperation),
            'taxes_total': pickSign(taxesTotal, isRefundOperation),
            'taxes': taxes
                .map(({designator, refundable, ticketPrice}) => ({
                    designator,
                    refundable,
                    amount: pickSign(ticketPrice, isRefundOperation)
                }))
                .filter(({amount}) => amount > 0),
            'total': pickSign(total, isRefundOperation),
            'total_credit': getTotalCredit(taxes, baseFareDifference),
        },
        'penalty': {
            amount: penalty,
            total: penalty
        },
        'commission': commission,
        'pricing_taxes': getPricingTaxes(taxes)
    };
};
