import {connect} from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import UserSettings from 'airborne/user_settings2/UserSettings';
import TermsConditions from 'airborne/termsConditions/TermsConditions';
import EmailOffers from 'airborne/email_offers';
import HotelSearch from 'airborne/search2/lazyloader';
import CarSearch from 'airborne/cars/search/lazyloader';
import AirSearch from 'airborne/air/fare_search/lazyloader';
import HotelCheckout from 'airborne/checkout2/lazyloader';
import CarCheckout from 'airborne/cars/checkout/lazyloader';
import AirCheckout from 'airborne/air/checkout/lazyloader';
import AgentHomepage from 'airborne/homepage2/components/AgentHomepage';
import PaymentManagerWrapper from 'airborne/homepage2/components/PaymentManagerWrapper';
import AirAvailabilityPage from 'airborne/air/availability';
import AirPricingPage from 'airborne/air/pricing';
import {isAirExchangeFlow} from 'airborne/store/modules/exchange_air/selectors';
import {Route, Switch} from 'react-router-dom';
import {modules} from 'midoffice/modules';
import {resetBookingUid, showNewRoute} from 'airborne/store/modules/header/actions/changeStep';
import ExchangeAir from 'airborne/air/exchange';
import {abortRequests} from 'airborne/store/modules/search_hotels/actions/navigate';
import {isNeedHomeRedirect, isNewRoute} from 'airborne/homepage2/helpers/routerHelper';
import history from 'airborne/browserHistory';
import {Redirect} from 'react-router';
import browserHistory from 'airborne/browserHistory';
import {hideModal} from 'airborne/store/modules/header/actions/modal';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {MicroFrontEnd} from 'airborne/microFrontEnds/MicroFrontEnds';
import {
    hideFeedbackWidget,
    shouldHideFeedbackWidget,
    shouldShowFeedbackWidget,
    showFeedbackWidget,
} from "airborne/utils/feedbackWidget";

@connect((state) => {
    const {homepage, dest: {view, product}} = state;
    const profile = getPnrProfile(state, 0) || {};

    // LOCK step to Booking Records because we have Modify modal functioning over it
    const step = isAirExchangeFlow(state) ? null: homepage.step;

    return {
        step,
        view,
        product,
        profile,
    };
}, {showNewRoute, hideModal, resetBookingUid})
class Step extends React.Component {
    static propTypes = {
        step: PropTypes.string,
        view: PropTypes.string.isRequired,
        profile: PropTypes.object,
        hideModal: PropTypes.func.isRequired,
        resetBookingUid: PropTypes.func.isRequired,
        showNewRoute: PropTypes.func.isRequired,
        product: PropTypes.oneOf(['cars', 'hotels', 'air', 'rail']).isRequired,
    };
    componentDidMount() {
        //in the old routing system replace our urls.
        //After removing old system need to remove function
        const {profile} = this.props;

        if (isNeedHomeRedirect(profile)) {
            browserHistory.push('/ui/homepage/');
        }

        this.unlisten = history.listen((params, type) => { //eslint-disable-line immutable/no-mutation

            if (type === 'POP' && !browserHistory.location.pathname.includes('/ui/email_offers/')) {
                this.props.hideModal();
                abortRequests();
                if (browserHistory.location.pathname.includes('ui/hotels/search')) {
                    this.props.resetBookingUid();
                }
            }

            if (isNewRoute()) {
                this.props.showNewRoute();
                this.updateFeedbackWidgetAppearance();
            }
        });
    }

    updateFeedbackWidgetAppearance() {
        if (shouldHideFeedbackWidget(browserHistory.location.pathname)) {
            hideFeedbackWidget();
        }
        if (shouldShowFeedbackWidget(browserHistory.location.pathname)) {
            showFeedbackWidget();
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        const {view, product} = this.props;
        let {step} = this.props;
        const stepComponents = {
            'email_offers': <EmailOffers />,
            'search': (() => {
                const Search = {
                    hotels: HotelSearch,
                    cars: CarSearch,
                    air: AirSearch,
                    rail: () => <MicroFrontEnd name="rail" />,
                }[product];
                return <Search asMap={view==='map'} />;
            })(),
            'air_availability': <AirAvailabilityPage />,
            'air_pricing': <AirPricingPage />,
            'checkout': (() => {
                const Checkout = {
                    hotels: HotelCheckout,
                    cars: CarCheckout,
                    air: AirCheckout,
                    rail: () => <MicroFrontEnd name="rail" />,
                }[product];
                return <Checkout />;
            })(),
        };
        const currentStep = stepComponents[step];
        return (<Switch>
            {(step === 'checkout' || step === 'air_pricing')  && currentStep}
            <Route path="/ui/terms-conditions/"><TermsConditions/></Route>
            <Route path="/ui/profile-settings/"><UserSettings/></Route>
            <Route path="/bookings/"> <ExchangeAir /> {modules('aft')}</Route>
            <Route path="/ui/homepage/"> <AgentHomepage/></Route>
            <Route path="/ui/payment_manager/"><PaymentManagerWrapper/></Route>
            <Route path="/ui/hotels/search/"> <HotelSearch asMap={view==='map'}/></Route>
            <Route path="/ui/cars/search/"> <CarSearch asMap={view==='map'}/></Route>
            <Route path="/ui/air/search/"> <AirSearch asMap={view==='map'}/></Route>
            <Route path="/ui/email_offers/"> <EmailOffers /></Route>
            <Route path="/ui/air_availability/"> <AirAvailabilityPage/></Route>
            <Route path="/ui/cars/checkout/"> <CarCheckout/></Route>
            <Route path="/ui/hotels/checkout/"> <HotelCheckout/></Route>
            <Route path="/ui/air/checkout/"> <AirCheckout/></Route>
            <Route path="/ui/rail/"> <MicroFrontEnd name="rail" /></Route>
            {step === 'pnr' && <Redirect to="/ui/homepage/" />}

            <Route path="/">
                {currentStep ? currentStep : <AgentHomepage/>}
            </Route>
        </Switch>
        );
    }
}

export default Step;
