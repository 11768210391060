import range from 'lodash/range';
import inRange from 'lodash/inRange';
import findKey from 'lodash/findKey';
import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';

import Glyphicons from 'midoffice/components/Glyphicons';


const MAX_STARS = 5;
export default class HotelRatings extends React.Component {
    static propTypes = {
        stars: PropTypes.number,
        trustYou: PropTypes.number,
        showTrustYou: PropTypes.bool,
        showEcoFilter: PropTypes.bool,
    };

    renderStars(stars) {
        return (
            <div className="hotel-card__rating-row__stars" data-value={stars} data-testid="hotel-star-rating">
                {range(1, stars + 1).map((idx)=> (
                    <Glyphicons bsClass="glyphicon" glyph="star" key={idx} className={(idx > stars) ? 'glyphicon-star--halfbg' : null} />
                ))}
                {range(Math.ceil(stars), MAX_STARS).map((idx)=> (
                    <Glyphicons bsClass="glyphicon" glyph="star" key={idx} className="glyphicon-star--grey" />
                ))}
            </div>
        );
    }

    renderTrustYou = () => {
        const {trustYou, showTrustYou, showEcoFilter} = this.props;
        const trustYouRanges = {
            red: [0.1, 3],
            yellow: [3, 4],
            green: [4, 5.1]
        };
        const getTrustYouLabel = (tyValue) => {
            const label = findKey(trustYouRanges, range => inRange(tyValue, ...range));
            return label ? `ty-rating ty-rating--${label}` : 'ty-rating';
        };
        return (<>
            {showTrustYou && <>
                <div className="ty-rating__title">
                    {gettext('TrustYou Rating')}
                </div>
                <div className={getTrustYouLabel(trustYou)}>
                    {trustYou ? trustYou : 'N/A'}
                </div>
            </>}
            {showEcoFilter && this.renderEcoFilter()}
        </>);
    }

    renderEcoFilter() {
        return (<div className="ty-eco">
            <div className="glyphicons glyphicons-leaf" />
        </div>);
    }

    render() {
        const {stars} = this.props;
        return (
            <div className="hotel-card__rating-row">
                {stars && this.renderStars(stars)}
                {this.renderTrustYou()}
            </div>
        );
    }
}
