import PropTypes from 'prop-types';
import React from 'react';
import cl from 'classnames';

import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';

import gettext from 'airborne/gettext';


export default class PrefIcons extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        client: PropTypes.bool,
        securityVetted: PropTypes.bool,
        labeling: PropTypes.object,
        clientByChain: PropTypes.bool,
        clientTier: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.bool,
        ]),
        tmc: PropTypes.bool,
        tmcByChain: PropTypes.bool,
        tmcTier: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.bool,
        ]),
        children: PropTypes.any,
    };

    getIconClass(isBcd, isChain, tier) {
        return isBcd ? cl({
            ['ico-agency-tier']: tier && !isChain,
            ['ico-agency-preferred']: !tier && !isChain,
            ['ico-agency-preferred-chain']: isChain && !tier,
            ['ico-agency-preferred-chain-tier']: isChain && tier,
        }) : cl({
            ['ico-tier']: tier && !isChain,
            ['ico-company-preferred']: !tier && !isChain,
            ['ico-company-preferred-chain']: isChain && !tier,
            ['ico-company-preferred-chain-tier']: isChain && tier,
        });
    }

    getLabel(isBcd, isChain) {
        let label;
        if (isChain) {
            label = isBcd ? gettext('BCD Travel preferred chain') : gettext('Company preferred chain');
        }
        if (!isChain) {
            label = isBcd ? gettext ('BCD Travel preferred') : gettext('Company preferred');
        }

        return label;
    }

    getAnnotateTooltipID(isBcd, isChain, clientTier) {
        let id;
        if (clientTier) {
            id = isBcd ? 'agency_preferred_tier' : 'company_preferred_tier';
        }
        else {
            id = isBcd ? 'agency_preferred' : 'company_preferred';
        }

        return id;
    }

    getIcon(isBcd, isChain, clientTier) {
        const casualDataPrint = isBcd ? gettext('BCD Travel preferred') : gettext('Company preferred');
        const tierDataPrint = isBcd ? gettext('BCD Travel preferred. Tier ').concat(clientTier) : gettext('Company preferred. Tier ').concat(clientTier);
        const iconClassName = this.getIconClass(isBcd, isChain, clientTier);
        return clientTier ? (
            <span className={iconClassName} data-print={tierDataPrint}>{clientTier}</span>
        ) : (
            <span className={iconClassName} data-print={casualDataPrint} />
        );
    }

    getSecurityIcon(securityVetted, labeling) {
        if (!securityVetted || !labeling?.security_vetted) {
            return null;
        }
        return (<AnnotateTooltip id={'security'}>
            <span className="ico-security-approved" data-print={gettext('Security Approved')} />
            {gettext('Security Approved')}
        </AnnotateTooltip>);
    }

    render() {
        const {className, client, securityVetted, labeling, clientTier, tmc, tmcTier, clientByChain, tmcByChain} = this.props;
        return (<div className={className} data-testid="preference-icons">
            {(client || clientByChain) && (
                <AnnotateTooltip id={this.getAnnotateTooltipID(false, clientByChain, clientTier)}>
                    {this.getIcon(false, clientByChain, clientTier)}
                    {this.getLabel(false, clientByChain)}
                </AnnotateTooltip>
            )}
            {(tmc || tmcByChain) && (
                <AnnotateTooltip id={this.getAnnotateTooltipID(true, tmcByChain, tmcTier)}>
                    {this.getIcon(true, tmcByChain, tmcTier)}
                    {this.getLabel(true, tmcByChain)}
                </AnnotateTooltip>
            )}
            {this.getSecurityIcon(securityVetted, labeling)}
            {this.props.children}
        </div>);
    }
}
