import {getFareGroupStoreKey} from 'airborne/air/store/fare_search/selectors';

const getExtra = (state) => state.airBooking.bookingExtra;

export const getBookingExtra = (state, ticketIndex = 0) => {
    const storeKey = getFareGroupStoreKey(state, ticketIndex);

    return getExtra(state)[storeKey];
};

export const getBookingExtraActualTotal = (state, ticketIndex = 0) =>
    getBookingExtra(state, ticketIndex)?.actualTotal;

export const getBookingExtraActualCurrency = (state) =>
    getBookingExtra(state)?.currency;
